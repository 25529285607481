import React from 'react';
import './style.css';
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import Home from './Home';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import OnlineVerification from './OnlineVerification';
import Courses from './Courses';

function App() {
  return (
    <>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" Component={Home} />
            <Route path="/about-us" Component={AboutUs} />
            <Route path="/contact-us" Component={ContactUs} />
            <Route path="/online-verification" Component={OnlineVerification} />
            <Route path="/courses" Component={Courses} />
          </Routes>
        </Router>
      </div>
    </>
  );
}

export default App;
