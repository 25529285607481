import React, { useEffect } from 'react';

function AboutUs() {

  useEffect(() => {
    document.title = 'About Us - National Educational & Vocational Institute - Pakistan';
  }, []);

  return (
    <>
      <div className="container-fluid bg-primary py-5 mb-5 page-header">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">About Us</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a className="text-white" href="">Home</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">About</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>


      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-4">
            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-graduation-cap text-primary mb-4"></i>
                  <h5 className="mb-3">Skilled Instructors</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-globe text-primary mb-4"></i>
                  <h5 className="mb-3">Online Classes</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-home text-primary mb-4"></i>
                  <h5 className="mb-3">Home Projects</h5>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6 wow fadeInUp" data-wow-delay="0.7s">
              <div className="service-item text-center pt-3">
                <div className="p-4">
                  <i className="fa fa-3x fa-book-open text-primary mb-4"></i>
                  <h5 className="mb-3">Book Library</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="container-xxl py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">
              <h6 className="section-title bg-white text-start text-primary pe-3">About Us</h6>
              <p className="mb-4 text-start">National Educational & Vocational Institute is a Professional organization, constituted for Manpower and Overseas Pakistanis. In addition to its accreditation with various top ranked organizations, colleges / universities abroad to excel distance learning on fast track, online, competency based and regular education for life experienced, matured and on-job professionals.</p>

              <p className="mb-4 text-start">NEVI operates on Public-Private Partnership. Active Participation of Employers Federation of Pakistan [EFP] and Private Sector Businessmen have created the success story of the NEVI, Rawalpindi. It is affiliated with Foreign Office, Foreign universities & colleges and is engaged in Distance Learning Education. NEVI is engaged to identify, develop and arrange Vocational, Technical / Professional and IT Training Programs. Training Programs are flexible, demand driven and cost effective with maximum participation from the Employers. NEVI Rawalpindi also offers tailor-made courses, according to the customers needs. It conducts Training Needs Assessment Surveys and schedules its training programs accordingly.</p>

              <p className="mb-4 text-start">To discuss your needs with one of our professionals, get in touch today.</p>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
