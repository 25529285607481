import React, { useEffect } from 'react';
import courses from './data/courses.json'

function Courses() {
  useEffect(() => {
    document.title = 'Courses - National Educational & Vocational Institute - Pakistan';
  }, []);
  var data = courses;
  return (
    <>
      <div className="container-fluid bg-primary py-5 mb-5 page-header">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">Courses</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a className="text-white" href="">Home</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Courses</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      {
        data.map(course => {
          return <div className="container-xxl py-5">
            <div className="container">
              <div className="row g-5">
                <div className="col-12 wow fadeInUp" data-wow-delay="0.3s">

                  <h6 className="section-title bg-white text-start text-primary pe-3 mb-5">{course.Name}</h6>

                  <div className="row gy-2 gx-4 mb-4 offset-3 col-6" style={{ textAlign: 'left' }}>
                    {
                      course.Courses.map(c => {
                        return <div className="col-sm-6">
                          <p className="mb-0"><i className="fa fa-arrow-right text-primary me-2"></i>{c}</p>
                        </div>
                      })
                    }

                  </div>

                </div>
              </div>
            </div>
          </div>
        })
      }
    </>
  );
}

export default Courses;
