import React, { useState, useEffect } from 'react';

function OnlineVerification() {

  useEffect(() => {
    document.title = 'Online Verification - National Educational & Vocational Institute - Pakistan';
  }, []);

  const [data, setData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [rollNumber, setRollNumber] = useState("");

  const getData = () => {

    if (!rollNumber) return;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' }
    };
    setShowError(false);
    try {
      fetch('https://api.nevi.org.pk/api/Results/?rollNumber=' + rollNumber, requestOptions)
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            setData(data.result);
          } else {
            setShowError(true);
            setData(null);
          }
        });
    } catch (error) {
      console.log("error", error);
    }
  }


  return (
    <>
      <div className="container-fluid bg-primary py-5 mb-5 page-header">
        <div className="container py-5">
          <div className="row justify-content-center">
            <div className="col-lg-10 text-center">
              <h1 className="display-3 text-white animated slideInDown">Online Verification</h1>
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb justify-content-center">
                  <li className="breadcrumb-item"><a className="text-white" href="/">Home</a></li>
                  <li className="breadcrumb-item text-white active" aria-current="page">Online Verification</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>

      <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title bg-white text-center text-primary px-3">Online Verification</h6>

            <h1 className="mb-5">Enter Roll Number</h1>

            <div className="col-md-4 offset-4">
              <div className="col-md-12">
                <div className="form-floating">
                  <input type="text" className="form-control" id="name" placeholder="Your Name" onChange={(e) => { setRollNumber(e.target.value) }} />
                  <label >Roll number</label>
                </div>
              </div>

              <div className="col-12 mt-5">
                <button className="btn btn-primary w-100 py-3" type="submit" onClick={() => { getData(); }}>Verify</button>
              </div>
              {showError && <div className='alert alert-danger mt-5'><strong>Error</strong> No result found!</div>}


            </div>


            {!showError && data &&
              <div className="card mt-5 ">
                <div className="card-header">
                  {(data as any).name}
                </div>
                <div className='row'>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-end">
                      <img style={{ maxWidth: '200px' }} src={(data as any).profileImage} />
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-start">
                      <h5 className="card-title d-inline-block">S.No:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).serialNumber}</p>
                    </div>
                    <div className="card-body text-lg-start col-4 float-end">
                      <h5 className="card-title d-inline-block">Roll No:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).rollNumber}</p>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-start">
                      <h5 className="card-title d-inline-block">Father / Husband Name:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).fatherName}</p>
                    </div>
                    <div className="card-body text-lg-start col-4 float-end">
                      <h5 className="card-title d-inline-block">Trade:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).trade}</p>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-12 float-start">
                      <h5 className="card-title d-inline-block">Conduct At:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).conductAt}</p>
                    </div>

                  </div>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-start">
                      <h5 className="card-title d-inline-block">Session:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).session}</p>
                    </div>
                    <div className="card-body text-lg-start col-4 float-end">
                      <h5 className="card-title d-inline-block">Duration:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).duration}</p>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-start">
                      <h5 className="card-title d-inline-block">Obtained Marks:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).OobtainedMarks}</p>
                    </div>
                    <div className="card-body text-lg-start col-4 float-end">
                      <h5 className="card-title d-inline-block">Total Marks:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).totalMarks}</p>
                    </div>
                  </div>
                  <div className='col-12'>
                    <div className="card-body text-lg-start col-4 float-start">
                      <h5 className="card-title d-inline-block">Percentage:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).percentage}</p>
                    </div>
                    <div className="card-body text-lg-start col-4 float-end">
                      <h5 className="card-title d-inline-block">Grade:</h5>
                      <p className="card-text d-inline-block" style={{ marginLeft: '3px' }}>{(data as any).grade}</p>
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </div>
      </div >
    </>
  );
}

export default OnlineVerification;
